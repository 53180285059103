export default {
  data: function data() {
    return {
      authToken: this.$route.query.code,
      app: this.$route.query.app
    };
  },
  head: function head() {
    return {
      title: this.$t('success.authentication')
    };
  },
  mounted: function mounted() {
    if (!window.opener) return;
    window.opener.postMessage({
      smapo: true,
      auth_token: this.authToken,
      app: this.app
    }, window.location.origin);
    this.$nextTick(function () {
      window.close();
    });
  }
};